.weeks__nav {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 0;
}

.weeks__nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
}

.weeks__nav li {
    padding: 0 0.6rem;
}
