.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.banner h1 {
    color: white;
    font-size: 4.3vw;
    width: 100%;
    text-align: center;
    background-color: #741188;
    margin: 1rem 0;
}

.banner__brand {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 70%;
}

.banner__info {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.banner_spacer {
    width: 35%;
    padding: 0.5rem;
}

.banner__info-city {
    width: 30%;
    background-color: white;
    padding: 0.5rem;
    text-align: center;
    font-size: 1rem;
    border-radius: 1rem 1rem 0 0;
}

.banner__info-user {
    width: 35%;
    text-align: right;
    padding: 0.5rem;
}

.banner__image-container {
    width: 100%;
    height: 100%;
    vertical-align: bottom;
    background-color: #741188;
}

.banner__image {
    width: 120px;
    height: 120px;
    min-width: 100px;
    vertical-align: middle;
}

.col-1 {
    width: 8.33%;
}
.col-2 {
    width: 16.66%;
}
.col-3 {
    width: 25%;
}
.col-4 {
    width: 33.33%;
}
.col-5 {
    width: 41.66%;
}
.col-6 {
    width: 50%;
}
.col-7 {
    width: 58.33%;
}
.col-8 {
    width: 66.66%;
}
.col-9 {
    width: 75%;
}
.col-10 {
    width: 83.33%;
}
.col-11 {
    width: 91.66%;
}
.col-12 {
    width: 100%;
    display: block;
}

[class*="col-"] {
    float: left;
    padding: 8px;
    width: 100%;
}

.row::after {
    clear: both;
    display: table;
}
