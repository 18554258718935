.select__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.select__label {
    font-size: 1rem;
    margin: 1rem;
}

.select__dropdown {
    font-size: 1rem;
    margin: 1rem;
    width: 12rem;
}
