.page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

main {
    margin: 1rem 1rem;
    width: 100%;
    height: 1300px;
}

.fullpage {
    background-color: white;
    height: 100%;
}
