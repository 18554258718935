.nav {
    background-color: #95bcf0;
    width: 100%;
    padding: 0.4rem;
    margin: auto;
}

.nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
}

.nav li {
    margin: 0;
    margin-left: 2rem;
}

.nav a {
    text-decoration: none;
    color: white;
}

.nav a:hover,
.nav a:active {
    color: #f3cafb;
    padding-bottom: 0.25rem;
    border-bottom: 4px solid #95bcf0;
}
