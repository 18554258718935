nav {
    width: 100%;
    padding: 0.4rem;
    /* margin: auto; */
}

.nav__main {
    background-color: #dd0db0;

    position: sticky;
    top: 0;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
}

nav li {
    margin: 0;
    margin-left: 2rem;
}

nav a {
    text-decoration: none;
    color: white;
}

nav a:hover,
nav a:active {
    color: #f3cafb;
    padding-bottom: 0.25rem;
    border-bottom: 4px solid #95bcf0;
}

nav button {
    font: inherit;
    background: #dd0db0;
    border: 1px solid #dd0db0;
    padding: 0.5rem 1.5rem;
    color: white;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
    border-radius: 20px;
}

nav button:focus {
    outline: none;
}

nav button:hover,
nav button:active {
    color: #f3cafb;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.26);
}
