@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Anonymous+Pro&family=Roboto&display=swap");

* {
    box-sizing: border-box;
}

html {
    font-family: "Noto Sans JP", sans-serif;
}

body {
    margin: 0;
    /* background-color: #3f3f3f; */
}

.centered {
    margin: 3rem auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
