.main-header {
    display: flex;
    width: 100%;
    height: 12rem;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: #741188;
    /* padding: 2rem 0; */
}
